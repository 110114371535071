import loadable from '@loadable/component';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  EyeIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { SanityLinkedAccountsBlockStaticQuery } from './__generated__/SanityLinkedAccountsBlockStaticQuery';
import useLinkedAccounts from './useLinkedAccounts';

const BLOCK_STATIC_QUERY = graphql`
  query SanityLinkedAccountsBlockStaticQuery {
    sanityLinkedAccountsBlock {
      title {
        ...LocaleString
      }
    }
  }
`;

const PlayerLinkedAccounts = loadable(() => import('./PlayerLinkedAccounts'));

const LinkedAccountsBlock: FC = () => {
  const { t } = useTranslate();

  const block = useStaticQuery<SanityLinkedAccountsBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityLinkedAccountsBlock;

  const {
    linkedAccounts,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useLinkedAccounts();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'linkedAccounts',
    data: linkedAccounts,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'playerId',
        title: 'Player ID',
      }),
      s.stringValue({
        field: 'firstName',
        title: 'First Name',
      }),
      s.stringValue({
        field: 'lastName',
        title: 'Last Name',
      }),
      s.stringValue({
        field: 'linkedAccounts',
        title: 'Linked Accounts',
        wrap: ({ row }) => (
          <div className="flex items-center">
            {row.linkedAccounts.length}
            <ControlledModal
              content={<PlayerLinkedAccounts playerId={row.playerId} />}
            >
              <InlineIconButton>
                <EyeIcon />
              </InlineIconButton>
            </ControlledModal>
          </div>
        ),
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />

          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default LinkedAccountsBlock;
